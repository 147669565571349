import axiosCustom from './customAxios';
import { Order, OrderStatusInfo, getOrdersResponse } from './models';

const path = 'orders/';
const path2 = 'order-status/';


export const getOrders = async (pageNumber = 1): Promise<getOrdersResponse> => {
  const { data, error } = await axiosCustom.get(`${path}?page=${pageNumber}`);
  if (error) {
    throw error;
  }
  return data;
};

export const trackOrder = async (
  orderId: number,
): Promise<OrderStatusInfo[]> => {
  const { data, error } = await axiosCustom.get(
    `orders/track/?number=${orderId}`,
  );
  if (error) {
    throw error;
  }
  return data;
};

export const getOrder = async (
  orderNumber: number,
): Promise<getOrdersResponse> => {
  const { data, error } = await axiosCustom.get(
    `${path}?number=${orderNumber}`,
  );
  if (error) {
    throw error;
  }
  return data;
};
export const getOrderByStatus = async (status: string,
  orderNumber: number,
): Promise<getOrdersResponse> => {
  const { data, error } = await axiosCustom.get(
    `${path2}?status=${status}&page=${orderNumber}`,
  );
  if (error) {
    throw error;
  }
  return data;
};