import React from 'react';
import { OptimizedImage } from 'components/common/OptimizedImage';

import star from '../../assets/star.png';


// Array of testimonials
const testimonials = [
  {
    name: "Ralph",
    location: "Auckland",
    text: "Mr Delivery Man dropped off this kibble a couple weeks ago. All I can say is it gets a two paws up from me!",
    imgFileName: "dog_1.png",
  },
  {
    name: "Elsa",
    location: "Dunedin",
    text: "Grain free, high protein and eco friendly!! It doesn’t get much better than that!",
    imgFileName: "dog_2.png",
  },
  {
    name: "Nadira",
    location: "Wellington",
    text: "Not only does the food just smell amazing, it is made using a natural recipe with chicken.",
    imgFileName: "dog_3.png",
  },
];

const CardWrapper = () => {
  return (
    <div className="card-wrapper def-w-max">
      <div className="row">
        {testimonials.map((testimonial, index) => (
          <div className="col-12 col-sm-4">
            <div className="testimonial-item">
              <div className="bg-img-wrap bg-img-1">
                <div className="image-container">
                  <OptimizedImage fileName={testimonial.imgFileName} />
                </div>
              </div>
              <div className="rating-wrapper clearfix text-center">
                {[...Array(5)].map((_, index) => (
                  <img src={star} alt="star" title="star" key={index} />
                ))}
              </div>
              <div className="text-center testimonial-txt">
                {testimonial.text}
              </div>
              <div className="testimonial-author text-center">
                {testimonial.name}, {testimonial.location}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardWrapper;
